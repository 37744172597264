@import "variables.scss";
@import "typography.scss";
@import "base.scss";
@import "header.scss";
@import "footer.scss";
@import "hero.scss";
@import "list.scss";
@import "todo.scss";


.blink-me {
    animation: blinker .7s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.is-text-small {
    font-size: .8em;
    font-style: italic;
    opacity: 0.7;
    vertical-align: top;
    line-height: 1.45;
}

.title {
    font-family: 'korolev', sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
}