html, body {
    margin: 0;
    font-family: 'BasierSquare', sans-serif;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    color: var(--color-primary);
    background: var(--color-light);
}

h1, h2 {
    font-family: korolev, sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 2px;
}

h2 {
    margin-top: 0;
    font-size: 6rem;
    line-height: 1.2em;
}

h3 {
    font-weight: 700;
}

section { 
    padding-top: 120px !important;
    padding-bottom: 120px !important;
}

img {
    max-width: 300px;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}



.bg-secondary {
    background: var(--color-secondary);
}
.bg-tertiary {
    background: var(--color-tertiary);
}





@media only screen and (max-width: 900px) {
    :root {
        --font-size: 18px;
        --grid-maxWidth: 90%;
    }

    h2 {
        font-size: 3rem
    }

    img {
        max-width: 220px;
    }
    /* section {
        padding: 8% 3%;
    } */

}