:root {
    --color-primary: #093934;
    --color-secondary: #DB2721;
    --color-tertiary: #E87613;
    --color-light: #F7EBED;
    --color-lightGrey: #F7EBED;
    --color-grey: #7e818b;
    --color-darkGrey: #3f4144;
    --color-error: #d43939;
    --color-success: #28bd14;
    --grid-maxWidth: 86%;
    /* --grid-maxWidth: 120rem; */
    --grid-gutter: 2rem;
    --font-size: 22px;
    /* --font-size: 1.6rem; */
    --font-family: "BasierSquare", "Helvetica Neue", sans-serif;
  }