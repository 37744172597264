ul {
    padding: 0;
    list-style: none;
}

.list {
    padding: 0;
}

.list > li {
    padding: 3rem 0;
    border-top: 1px solid var(--color-light);
}

.tabular-list {
    padding-left: 100px;
    position: relative;
    li {
        padding: .5rem 0;
    }
    strong {
        position: absolute;
        left: 0;
    }
}

@media only screen and (max-width: 900px) {
    .tabular-list {
        padding-left: 70px;
    }
}