@font-face {
    font-family: 'BasierSquare';
    src: url('/src/fonts/basiersquare-regular-webfont.woff2') format('woff2'),
         url('/src/fonts/basiersquare-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'BasierSquare';
    src: url('/src/fonts/basiersquare-regularitalic-webfont.woff2') format('woff2'),
         url('/src/fonts/basiersquare-regularitalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'BasierSquare';
    src: url('/src/fonts/basiersquare-semibold-webfont.woff2') format('woff2'),
         url('/src/fonts/basiersquare-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}