.hero {

    padding-top: 40px !important;
    padding-bottom: 40px !important;

    h1 {
        font-size: 7vw;
        // font-size: 10vw;
        margin: 0 0 2rem;
        // margin: 0 0 4rem;
        line-height: 1em;
    }
    
    span {
        font-size: 1.3em;
        // font-size: 1.5em;
        font-weight: 600;
    }
    
    .button {
        background-color: var(--color-secondary);
        border-radius: 40px;
        font-size: 0.7em;
        padding: .8em 1.8em;
        letter-spacing: 2px;
        font-weight: 600;
    }
    
    

    .hero-image {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100vh + 100px);
        width: 100%;

        img {
            position: absolute;
            margin: 0;
        }
        .human-1 {
            left: 2%;
            top: 0%;
        }
        .human-2 {
            left: 5%;
            bottom: 0%;
        }
        .human-3 {
            right: 20%;
            top: 0%;
        }
        .human-4 {
            right: 5%;
            bottom: 0%;
        }
    }
}



@media only screen and (max-width: 900px) {
    .hero h1 {
        font-size: 20vw;
    }

    .hero-image {
        overflow: hidden;

        .human-2 {
            bottom: 5%;
            left: -8%;
        }
        .human-3 {
            display: none;
        }
    }
}

@media only screen and (max-width: 600px) {
    .hero-image {
        .human-1 {
            left: 1%;
            top: -5%;
        }
        .human-2 {
            left: -10%;
        }
        .human-4 {
            right: -5%;
        }
    }
}
@media only screen and (max-width: 400px) {
    .hero-image .human-2 {
        left: -18%;
    }
}