footer {

    h1 {
        font-size: 8vw;
        margin: 0;
    }
    
    img {
        max-width: 300px;
        margin: 0;
    }
}


@media only screen and (max-width: 900px) {
    footer h1 {
        font-size: 6rem;
    }
}