.todo {}

.todo li {
    padding: .5rem 0;
}

.todo .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 3px solid var(--color-primary);
}

.todo label {
    padding-left: 40px;
    position: relative;
    cursor: pointer;
}

.todo input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

}
.todo input:checked ~ span {
    text-decoration: line-through;
}

.todo input:checked ~ .checkmark {
    background-color: var(--color-primary) !important;
}

.todo label:hover input ~ .checkmark {
    background-color: #ccc;
}