#logo {
    top: 20px;
    right: 20px;
    max-width: 100px;
    // max-width: 120px;
    transform: translateX(-50%) rotate( calc(var(--scrollY) * 360deg) );
    mix-blend-mode: multiply;
}

@media only screen and (max-width: 900px) {
    #logo {
        max-width: 80px;
        top: -10px;
        right: -10px;
    }
}

@media only screen and (max-width: 600px) {
    #logo {
        max-width: 60px;
    }
}